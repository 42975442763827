import * as THREE from "three"
import { CustomUniforms } from "../../_helpers/CustomUniforms.js"

export function WaveFog(shaderOpacity, bw, r, g, b) {
  const material = new THREE.MeshPhysicalMaterial({
    color: "#ccc",
    metalness: "1.0",
    roughness: "0.1",
  })
  material.onBeforeCompile = (shader) => {
    shader.uniforms.time = CustomUniforms.uTimeV
    shader.uniforms.transTime = CustomUniforms.transTime
    shader.uniforms.shaderOpacity = shaderOpacity
    shader.uniforms.bw = bw
    shader.uniforms.cR = r
    shader.uniforms.cG = g
    shader.uniforms.cB = b

    shader.vertexShader = shader.vertexShader.replace(
      "#include <uv_pars_vertex>",
      `
                                    	#include <uv_pars_vertex>
                                        varying vec2 kUv;
                                `,
    )

    shader.vertexShader = shader.vertexShader.replace(
      "#include <fog_vertex>",
      `
                                    	#include <fog_vertex>

                                        kUv = uv;
                                        vNormal =  normal * normal * normal ;
                                `,
    )

    shader.fragmentShader = shader.fragmentShader.replace(
      "#include <clipping_planes_pars_fragment>",
      `
                                    #include <clipping_planes_pars_fragment>
                                    varying vec2 kUv;
                                    uniform vec3 gcolor;
                                    uniform float time;
                                    uniform float transTime;
                                    uniform float shaderOpacity;
                                    uniform float bw;
                                    uniform float cR;
                                    uniform float cG;
                                    uniform float cB;

                                    float random( in vec2 _st) {
                                        return fract(sin(dot(_st.xy,
                                                vec2(12.9898, 78.233))) *
                                            43758.5453123);
                                    }


                                    // Based on Morgan McGuire @morgan3d
                                    // https://www.shadertoy.com/view/4dS3Wd
                                    float noise( in vec2 _st) {
                                        vec2 i = floor(_st);
                                        vec2 f = fract(_st);

                                        // Four corners in 2D of a tile
                                        float a = random(i);
                                        float b = random(i + vec2(1.0, 0.0));
                                        float c = random(i + vec2(0.0, 1.0));
                                        float d = random(i + vec2(1.0, 1.0));

                                        vec2 u = f * (3.0 - 2.0 * f);

                                        return mix(a, b, u.x) +
                                            (c - a) * u.y * (1.0 - u.x) +
                                            (d - b) * u.x * u.y;
                                    }

                                    #define NUM_OCTAVES 4

                                    float fbm( in vec2 _st) {
                                        float v = 0.0;
                                        float a = 0.5;
                                        vec2 shift = vec2(50.0);
                                        // Rotate to reduce axial bias
                                        mat2 rot = mat2(cos(0.75), sin(0.15),
                                            -sin(0.5), cos(0.50));
                                        for (int i = 0; i < NUM_OCTAVES; ++i) {
                                            v += a * noise(_st);
                                            _st = rot * _st * 2.0 + shift;
                                            a *= 0.5;
                                        }
                                        return v;
                                    }


                                `,
    )
    shader.fragmentShader = shader.fragmentShader.replace(
      "#include <dithering_fragment>",
      `

                                    #include <dithering_fragment>
                                    vec2 resolution = vec2(.0);
                                    resolution.x = 1280.;
                                    resolution.y = 800.;

                                    // vec2 mouse = (0.5);
                                    // mouse.x (0.5);
                                    float time = time * 8.0;

                                    vec2 st = gl_FragCoord.xy/resolution.xy*PI;

                                   vec2 q = vec2(0.);
                                   q.x = fbm( st + 0.50*time);
                                   q.y = fbm( st + vec2(1.0));

                                   vec2 r = vec2(0.);
                                   r.x = fbm( st + 1.0*q + vec2(5.2,2.2)+ 0.125*time );
                                   r.y = fbm( st + 1.0*q + vec2(10.3,2.8)+ 0.15*time);

                                   float f = fbm(st+r);

                                   vec3 gcolor = vec3(0.0);
                                   gcolor = mix(vec3(0.5,0.0,0.0),vec3(0.2,0.1,0.25),clamp(length(q.x),0.2,0.2));
                                   gcolor = mix(vec3(cR,cG,cB),vec3(0.2,0.1,0.25),clamp(length(r.x*0.5),0.,1.0));
                                   gcolor = mix(vec3(cR,cG,cB),vec3(0.7,0.1,0.25),clamp(length(r.x*0.2),(r.y*0.2),1.0));


                                      vec3 mixcolor = vec3(0.0);
                                      vec3 bgcolor = vec3(0.08);

                                      mixcolor = mix(gcolor, bgcolor, shaderOpacity);


                                      gl_FragColor = vec4((f*f*f+bw*f*f+.5*f)*mixcolor,1.);
                                `,
    )
  }
  material.skinning = true
  return material
}
