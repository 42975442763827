import React, { useRef } from "react"
import { useGLTF } from "@react-three/drei/core/useGLTF"
import { useFrame } from "react-three-fiber"

export default function Plant({ position, scale, rotation, ...props }) {
  const group = useRef()

  const { nodes, materials } = useGLTF("models/plant04d.glb")

  useFrame(
    ({ clock, state }) => (
      (group.current.rotation.x = group.current.rotation.y = group.current.rotation.z = Math.sin(clock.getElapsedTime() + 3.5) * 0.05),
      (group.current.children[0].rotation.z = group.current.children[0].rotation.y = Math.sin(clock.getElapsedTime() + 3.5) * 0.25)
    ),
  )
  return (
    <group {...props} ref={group}>
      <mesh geometry={nodes.Protea_002.geometry} position={position} rotation={rotation} scale={scale}>
        <meshStandardMaterial metalness={1} roughness={0.05} color={"purple"} skinning wireframe />
      </mesh>
    </group>
  )
}

useGLTF.preload("models/plantd04d.glb")
// material={WhitePhysicalMaterial(CustomUniforms.bool.y)}
