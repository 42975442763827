import { TextureLoader } from "three"
import React, { useState } from "react"
import { Canvas, useLoader } from "react-three-fiber"
import { MeshWobbleMaterial, MeshDistortMaterial } from "@react-three/drei"
import { useSpring, a, config } from "react-spring/three"

const AnimatedWobbleMaterial = a(MeshWobbleMaterial)

export default function Image({ imgPath, rotation, position, texture, width, height }) {
  texture = useLoader(TextureLoader, imgPath)

  return (
    <a.mesh position={position} rotation={rotation}>
      <planeBufferGeometry args={[3, 3, 16, 16]} />
      <MeshWobbleMaterial attach="material" factor={0.5} map={texture} />
    </a.mesh>
  )
}
