import * as THREE from "three"
import useDeviceOrientation from "react-hook-device-orientation"
import { useThree, useFrame } from "react-three-fiber"

function Rig(mobileRightClicked, mobileLeftClicked) {
  let x, y, z
  const deviceOrientation = useDeviceOrientation()
  const normalizedAlpha = deviceOrientation.alpha / 360 - 0.25
  const normalizedBeta = deviceOrientation.beta / 360 - 0.05
  const normalizedGamma = deviceOrientation.gamma / 360

  // useFrame(({ clock, state }) => {
  x = normalizedBeta
  y = normalizedAlpha
  z = normalizedGamma
  // })

  const { camera, mouse } = useThree()
  const vec = new THREE.Vector3()
  const isIOS = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  if (isIOS) {
    if (mobileRightClicked.mobileLeftClicked) {
      return useFrame(() => {
        camera.position.lerp(vec.set(-0.4, 0.2, camera.position.z), 0.02)
      })
    } else if (mobileRightClicked.mobileRightClicked) {
      return useFrame(() => {
        camera.position.lerp(vec.set(0.4, -0.2, camera.position.z), 0.02)
      })
    } else {
      return useFrame(() => {
        camera.position.lerp(vec.set(0, 0, camera.position.z), 0.02)
        camera.rotation.set(0, 0, camera.rotation.z)
      })
    }
  } else {
    return useFrame(() => camera.position.lerp(vec.set(mouse.x / 2, (mouse.y / 2) * 1, camera.position.z), 0.02))
  }
}

export default Rig
