import React, { useRef, useState, Suspense } from "react"
import { render } from "react-dom"
import { useTransition, a } from "react-spring"
import { useLocation } from "wouter"
import { Canvas } from "react-three-fiber"
import { getMousePos } from "./_helpers/utils"
import Home from "./views/Home.js"

import "./styles.css"

export default function App() {
  const mouse = useRef({ x: 0, y: 0 })
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const [mobileLeftClicked, setMobileLeftClicked] = useState(false)
  const [mobileRightClicked, setMobileRightClicked] = useState(false)

  const [location] = useLocation()
  const transitions = useTransition(location, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0, transform: "translate3d(-50%,0,0)" },
  })
  return transitions.map(({ item: location, props, key }) => (
    <a.div key={key} style={props} className="full">
      {isMobile && (
        <div>
          <div
            onClick={() => {
              setMobileLeftClicked(!mobileLeftClicked), setMobileRightClicked(false)
            }}
            className="left-mobile-hitbox"></div>
          <div
            onClick={() => {
              setMobileRightClicked(!mobileRightClicked), setMobileLeftClicked(false)
            }}
            className="right-mobile-hitbox"></div>
        </div>
      )}
      <Canvas
        onMouseMove={(e) => (mouse.current = getMousePos(e))}
        camera={{ fov: 75, position: [0, 0, 3.5] }}
        dpr={(Math.min(window.devicePixelRatio), 2)}
        performance={{ min: 1 }}>
        <Suspense fallback={null}>
          <Home mouse={mouse} mobileLeftClicked={mobileLeftClicked} mobileRightClicked={mobileRightClicked} />
          <ambientLight intensity={1.0} />
        </Suspense>
      </Canvas>
    </a.div>
  ))
}

render(<App />, document.getElementById("root"))
