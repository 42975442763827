import React, { useRef, useEffect, useState } from "react"
import { Box } from "@react-three/drei"
import { useFrame, useThree } from "react-three-fiber"
import { useSpring, a, config } from "react-spring/three"
import Phone from "../model/Phone.js"
import Title from "../elements/Title.js"
import { COLORS } from "../_helpers/Colors.js"
import { WaveFog } from "../_shaders/threeMod/WaveFog.js"

import { Donut } from "../_shaders/threeMod/Donut.js"

const APhone = a(Phone)
const isIOS = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

function BoxL(mobileLeftClicked, mobileRightClicked) {
  const group = useRef()
  const { viewport } = useThree()
  const [hovered, setHovered] = useState(false)

  const props = useSpring({
    // Phone
    position: hovered ? [viewport.width / 2.5, -0.5, -2] : [1, -5, -3],
    scale: hovered ? [1.25, 1.35, 1.35] : [0.5, 0.5, 0.5],
    rotation: hovered ? [1.5, -0.3, 0.7] : [1.7, -0.1, 6.0],

    positionIOS: mobileLeftClicked.mobileRightClicked ? [viewport.width / 2, -1.5, -4] : [1, -5, -4],
    scaleIOS: mobileLeftClicked.mobileRightClicked ? [1.25, 1.25, 1.25] : [0.5, 0.5, 0.5],
    rotationIOS: mobileLeftClicked.mobileRightClicked ? [1.5, -0.3, 0.9] : [1.5, -0.3, 6.9],

    // Text
    color: hovered ? COLORS.black : "#0b0b0b",
    oColor: hovered ? COLORS.white : "#0b0b0b",
    outlineColor: hovered ? COLORS.white : "#0b0b0b",

    color: mobileLeftClicked.mobileRightClicked ? COLORS.black : "#0b0b0b",
    oColor: mobileLeftClicked.mobileRightClicked ? "pink" : "#0b0b0b",
    outlineColor: mobileLeftClicked.mobileRightClicked ? "pink" : "#0b0b0b",
    // Pushcom

    // lightpos
    lightPos: hovered ? [10, 10, -2] : [-4, -8, -16],
    lightPos02: hovered ? [10, 2, 0] : [-4, -8, -16],
    lightPos03: hovered ? [10, 4, 5] : [-4, -8, -16],
    intensity: hovered ? 0.05 : 0,

    //shader opacity
    shaderOpacity: hovered ? 0.8 : 0.8,
    fogC: hovered ? 12.0 : 0.0,
    shaderColorR: hovered ? 0.01 : 0.1,
    shaderColorG: hovered ? 0.01 : 0.1,
    shaderColorB: hovered ? 0.01 : 0.1,
    trans: hovered ? 0.1 : 0.8,
    mobiletrans: 0.2,

    //shader opacity
    shaderOpacity: 0.65,
    fogCmobile: 8.0,
    shaderColorR: 0.4,
    shaderColorG: 0.1,
    shaderColorB: 1.1,

    // General Config
    config: {
      tension: 16,
      friction: 8,
    },
  })

  return (
    <group ref={group} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
      {isIOS ? (
        <Box
          rotation={[0.0, 0.0, 0.0]}
          material={WaveFog(props.shaderOpacity, props.fogCmobile, props.shaderColorR, props.shaderColorG, props.shaderColorB)}
          args={[viewport.width * 4.0, viewport.height * 5.5]}
          position={[viewport.width * 1.0, 0, -8.1]}></Box>
      ) : (
        <Box
          material={Donut(props.trans)}
          rotation={[0.0, 0.0, -0.2]}
          args={[viewport.width * 1.9, viewport.height * 4.5]}
          position={[viewport.width * 1.03, 0, -8]}></Box>
      )}

      {isIOS ? (
        <APhone position={props.positionIOS} rotation={props.rotationIOS} scale={props.scaleIOS} />
      ) : (
        <APhone position={props.position} rotation={props.rotation} scale={props.scale} />
      )}

      {isIOS ? (
        <Title color={props.oColor} outlineColor={props.outlineColor} content="Digital" position={[viewport.width / 2.6, 2.7, -1.0]} />
      ) : (
        <Title color={props.oColor} outlineColor={props.outlineColor} content="Digital" position={[viewport.width / 3, 0, -0.5]} />
      )}

      <a.spotLight position={props.lightPos} intensity={props.intensity} color={COLORS.c06} />
      <a.spotLight intensity={props.intensity} position={props.lightPos02} color={COLORS.c01} />
      <a.spotLight intensity={props.intensity} position={props.lightPos03} color={COLORS.c06} />
      <a.rectAreaLight />
    </group>
  )
}

export default BoxL
