import * as THREE from "three"
import textureImg from '../shaders/p03.jpg'
import textureImgS from '../shaders/11.jpg'

const sTexture = textureImg;
const sTextureS = textureImgS;

export const CustomUniforms = {
    uResolution: {
        x: window.innerWidth,
        y: window.innerHeight
    },
    uTime: {
        value: 0
    },
    uTimeV: {
        value: 0
    },
    uTexture: {
        type: "t",
        value: new THREE.TextureLoader().load(sTexture)
    },
    uTextureS: {
        type: "t",
        value: new THREE.TextureLoader().load(sTextureS)
    },
    transTime: {
        value: 0
    },
    colorR: {
        value: 1.0
    },
    bool: {
        x: false,
        y: true
    },
    rgb: {
        r: 0.0,
        g: 0.0,
        b: 0.0
    }
}
