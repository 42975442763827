import * as THREE from "three"
import { getMouseDegrees } from "../_helpers/utils"

export function MoveJoint(mouse, joint, degreeLimit = 20) {
  let degrees = getMouseDegrees(mouse.current.x, mouse.current.y * 0.75, degreeLimit)
  joint.rotation.xD = THREE.MathUtils.lerp(joint.rotation.xD || 0, degrees.y, 0.1)
  joint.rotation.yD = THREE.MathUtils.lerp(joint.rotation.yD || 0, degrees.x, 0.1)
  joint.rotation.x = THREE.Math.degToRad(joint.rotation.xD)
  joint.rotation.y = THREE.Math.degToRad(joint.rotation.yD)
}

export function MoveJointNeck(mouse, joint, degreeLimit = 3) {
  let degrees = getMouseDegrees(mouse.current.x, mouse.current.y * 0.75, degreeLimit)
  joint.rotation.xD = THREE.MathUtils.lerp(joint.rotation.xD || 0, degrees.y, 0.1)
  joint.rotation.yD = THREE.MathUtils.lerp(joint.rotation.yD || 0, degrees.x, 0.1)
  joint.rotation.x = THREE.Math.degToRad(joint.rotation.xD)
  joint.rotation.y = THREE.Math.degToRad(joint.rotation.yD)
}
