const state = {
    content: [{
            color: '#cccccc',
            textColor: '#ffffff',
            text: 'In a void,\nno one could say\nwhy a thing\nonce set in motion\nshould stop anywhere.',
            image: '../img/p01.jpg',
        },
        {
            textColor: '#272727',
            text: 'For why should it stop\nhere rather than here?\nSo that a thing\nwill either be at rest\nor must be moved\nad infinitum.',
            image: '../img/p02.jpg',
        },
    ]
}

export default state
