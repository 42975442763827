export const COLORS = {
  c01: '#ebb94b',
  c02: '#139a51',
  c03: '#68a6f9',
  c03b: '#0003ff',
  c04: '#ed93ec',
  c05: '#ff4763',
  c06: '#eb934b',

  white: '#fff',
  gray: '#8b8b8b',
  black: '#000',
}
