import * as THREE from "three"
import {CustomUniforms} from '../../_helpers/CustomUniforms.js'


export function Galaxy() {
    const material = new THREE.MeshPhysicalMaterial({color: "#000", metalness: "0.5", roughness: "0.55"});
    material.onBeforeCompile = (shader) => {
        shader.uniforms.iTime = CustomUniforms.uTime;
        shader.vertexShader = shader.vertexShader.replace('#include <uv_pars_vertex>', `
                            	#include <uv_pars_vertex>
                                varying vec2 kUv;
                        `)

        shader.vertexShader = shader.vertexShader.replace('#include <fog_vertex>', `
                            	#include <fog_vertex>

                                kUv = uv;
                                vNormal =  normal * 0.5 + 0.3;
                        `)

        shader.fragmentShader = shader.fragmentShader.replace('#include <clipping_planes_pars_fragment>', `
                            #include <clipping_planes_pars_fragment>

                            varying vec2 kUv;
                            uniform float iTime;


                            float field(in vec3 p,float s) {
                            	float strength = 7. + .03 * log(1.e-6 + fract(sin(iTime) * 4373.11));
                            	float accum = s/4.;
                            	float prev = 0.;
                            	float tw = 0.;
                            	for (int i = 0; i < 26; ++i) {
                            		float mag = dot(p, p);
                            		p = abs(p) / mag + vec3(-.5, -.4, -1.5);
                            		float w = exp(-float(i) / 7.);
                            		accum += w * exp(-strength * pow(abs(mag - prev), 2.2));
                            		tw += w;
                            		prev = mag;
                            	}
                            	return max(0., 5. * accum / tw - .7);
                            }

                            // Less iterations for second layer
                            float field2(in vec3 p, float s) {
                            	float strength = 7. + .03 * log(1.e-6 + fract(sin(iTime) * 4373.11));
                            	float accum = s/4.;
                            	float prev = 0.;
                            	float tw = 0.;
                            	for (int i = 0; i < 18; ++i) {
                            		float mag = dot(p, p);
                            		p = abs(p) / mag + vec3(-.5, -.4, -1.5);
                            		float w = exp(-float(i) / 7.);
                            		accum += w * exp(-strength * pow(abs(mag - prev), 2.2));
                            		tw += w;
                            		prev = mag;
                            	}
                            	return max(0., 5. * accum / tw - .7);
                            }

                            vec3 nrand3( vec2 co )
                            {
                            	vec3 a = fract( cos( co.x*7.3e-3 + co.y )*vec3(155.3e5, 4.7e5, 2.9e5) );
                            	vec3 b = fract( sin( co.x*0.3e-3 + co.y )*vec3(8.1e5, 1.0e5, 0.1e5) );
                            	vec3 c = mix(a, b, 0.5);
                            	return c;
                            }


                            void mainImage( out vec4 fragColor, in vec2 fragCoord ) {
                                vec2 iResolution = vec2(2048.0);
                                float phi = acos(vNormal.y);
                                float angle = atan(vNormal.x,vNormal.z);

                                vec2 fakeUV = vec2( (angle + PI)/(2.*PI), phi/PI );

                                vec2 uv = fakeUV;
                            	vec2 uvs = uv * iResolution.xy / max(iResolution.x, iResolution.y);
                            	vec3 p = vec3(uvs / 4., 0) + vec3(1., -1.3, 0.);
                            	p += .2 * vec3(sin(iTime / 32.), sin(iTime / 24.),  sin(iTime / 128.));

                            	float freqs[4];
                            	//Sound
                            	freqs[0] = 0.1;
                            	freqs[1] = 0.1;
                            	freqs[2] = 0.8;
                            	freqs[3] = 0.7;

                            	float t = field(p,freqs[2]);
                            	float v = (1. - exp((abs(uv.x) - 1.) * 6.)) * (1. - exp((abs(uv.y) - 1.) * 6.));

                                //Second Layer
                            	vec3 p2 = vec3(uvs / (4.+sin(iTime*0.05)*0.2+0.2+sin(iTime*0.15)*0.3+0.4), 1.5) + vec3(2., -1.3, -1.);
                            	p2 += 0.25 * vec3(sin(iTime / 16.), sin(iTime / 12.),  sin(iTime / 128.));
                            	float t2 = field2(p2,freqs[3]);
                            	vec4 c2 = mix(.4, 1., v) * vec4(1.3 * t2 * t2 * t2 ,0.8  * t2 * t2 , t2* freqs[0], t2);


                            	//Let's add some stars
                            	//Thanks to http://glsl.heroku.com/e#6904.0
                            	vec2 seed = p.xy * 2.0;
                            	seed = floor(seed * iResolution.x);
                            	vec3 rnd = nrand3( seed );
                            	vec4 starcolor = vec4(pow(rnd.y,10.0));

                            	//Second Layer
                            	vec2 seed2 = p2.xy * 2.0;
                            	seed2 = floor(seed2 * iResolution.x);
                            	vec3 rnd2 = nrand3( seed2 );
                            	starcolor += vec4(pow(rnd2.y,40.0));

                            	fragColor = mix(freqs[3]-.4, 1.0, v) * vec4(1.5*freqs[2] * t * t* t , 1.8*freqs[1] * t * t, freqs[3]*t, 1.0)+c2+starcolor;
                            }


                        `)
        shader.fragmentShader = shader.fragmentShader.replace('#include <dithering_fragment>', `
                            #include <dithering_fragment>
                            float phi = acos(vNormal.y);
                            float angle = atan(vNormal.x,vNormal.z);
                            vec2 mouse = vec2(0.25,0.145);


                            vec4 mcolor = vec4(1.0);

                                mainImage(mcolor, gl_FragCoord.xy);

                                vec4 outg = vec4(outgoingLight, diffuseColor.a );
                                vec4 gcolor = mix(outg, mcolor, 0.55);

                                gl_FragColor = gcolor;

                        `)

    };

    material.skinning = true;
    return material;

}
