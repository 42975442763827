import * as THREE from "three"
import {CustomUniforms} from '../../_helpers/CustomUniforms.js'


export function Donut(trans) {
    const material = new THREE.MeshPhysicalMaterial({color: "#111", metalness: "1", roughness: "0.15"});
    material.onBeforeCompile = (shader) => {
        shader.uniforms.time = CustomUniforms.time;
        shader.uniforms.uTrans = trans;
        shader.vertexShader = shader.vertexShader.replace('#include <uv_pars_vertex>', `
                            	#include <uv_pars_vertex>
                                varying vec2 kUv;
                        `)

        shader.vertexShader = shader.vertexShader.replace('#include <fog_vertex>', `
                            	#include <fog_vertex>

                                kUv = uv;
                                vNormal =  normal * 0.5 + 0.3;
                        `)

        shader.fragmentShader = shader.fragmentShader.replace('#include <clipping_planes_pars_fragment>', `
                            #include <clipping_planes_pars_fragment>

                            varying vec2 kUv;
                            uniform float time;
                            uniform float uTrans;

                            #define rot(a) mat2(cos(a),-sin(a),sin(a),cos(a))

                            float map(vec3 p) {
                            	p.yz *= sin(uTrans);
                            	// p.zx *= rot(time * 0.3);
                            	vec2 q = vec2(length(p.xy) - 3.0, p.z);
                            	return length(q) - 0.5;
                            }


                        `)
        shader.fragmentShader = shader.fragmentShader.replace('#include <dithering_fragment>', `
                            #include <dithering_fragment>
                            vec3 rd = normalize(vec3(kUv, 2));
                            	vec3 p = vec3(0, 0, -1);

                            	float dist = 0.0;
                            	for (int i = 0; i < 10; i++) {
                            		float d = map(p + rd * dist);
                            		if (d < 0.001) {
                            			break;
                            		}
                            		dist += d;
                            	}

                            	float bright = 100.0 / (dist * dist);
                            	gl_FragColor = vec4(vec3(0.7,0.,1.0) * bright, 1);
                        `)

    };

    material.skinning = true;
    return material;

}
