import React, { useRef, useEffect } from "react"
import { useThree } from "react-three-fiber"
import AnimatedText from "../_helpers/AnimatedText"

function Title({ color, content, outlineColor, roughness, layers, ...props }) {
  const group = useRef()
  const { viewport } = useThree()

  const isIOS = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  let fontSize = viewport.width * 0.1

  if (isIOS) {
    fontSize = viewport.width * 0.15
  }

  useEffect(() => {
    group.current.lookAt(0, 0, 90)
  }, [])

  return (
    <group {...props} ref={group}>
      <AnimatedText
        name="N"
        fontSize={fontSize}
        color={color}
        maxWidth="1"
        font="fonts/NewYork.woff"
        outlineWidth={0.005}
        outlineColor={outlineColor}>
        {content}
      </AnimatedText>
    </group>
  )
}
// <meshPhysicalMaterial metalness={1} roughness={1.5} color={color}  />

export default Title
