import * as THREE from "three"
import {CustomUniforms} from '../../_helpers/CustomUniforms.js'


export function LineMaterial(shaderOpacity) {
    const material = new THREE.MeshStandardMaterial({color: "#000"});
    material.onBeforeCompile = (shader) => {

        shader.uniforms.time = CustomUniforms.uTime;
        shader.uniforms.shaderOpacity = shaderOpacity;


        shader.vertexShader = shader.vertexShader.replace('#include <uv_pars_vertex>', `
                            	#include <uv_pars_vertex>
                                varying vec2 kUv;
                        `)

        shader.vertexShader = shader.vertexShader.replace('#include <fog_vertex>', `
                            	#include <fog_vertex>

                                kUv = uv;
                                vNormal =  normal * 0.5 + 0.3;
                        `)

        shader.fragmentShader = shader.fragmentShader.replace('#include <clipping_planes_pars_fragment>', `
                            #include <clipping_planes_pars_fragment>

                            varying vec2 kUv;
                            uniform float time;
                            uniform float shaderOpacity;


                            float random (in float x) {
                                return fract(sin(x)*1e4);
                            }

                            float random (in vec2 st) {
                                return fract(sin(dot(st.xy, vec2(12.9898,78.233)))* 43758.5453123);
                            }

                            float pattern(vec2 st, vec2 v, float t) {
                                vec2 p = floor(st+v);
                                return step(t, random(100.+p*.000001)+random(p.x)*0.5 );
                            }

                        `)
        shader.fragmentShader = shader.fragmentShader.replace('#include <dithering_fragment>', `
                            #include <dithering_fragment>
                            float phi = acos(vNormal.y);
                            float angle = atan(vNormal.x,vNormal.z);


                            vec2 fakeUV = vec2( (angle + PI)/(2.*PI), phi/PI );
                            // fakeUV = fract(fakeUV + vec2(time/4., time/2.));
                            // fakeUV = abs(fakeUV);

                            vec2 st = gl_FragCoord.xy/fakeUV.yx/21.+time;
                            vec2 grid = vec2(0.5,0.5);
                            st *= grid;

                            vec2 ipos = floor(st);  // integer
                            vec2 fpos = fract(st);  // fraction

                            vec2 vel = vec2(time*1.*max(grid.x,grid.y)); // time
                            vel *= vec2(15.0,2.0) * random(0.1+ipos.y); // direction

                            vec2 offset = vec2(0.1,0.);

                            vec3 color = vec3(0.);
                            color.r = pattern(st+offset,vel,sin(0.5));
                            color.g = pattern(st,vel,sin(0.5));
                            color.b = pattern(st-offset,vel,sin(0.5));

                            // Margins
                            color *= step(0.5,fpos.y);


                            vec3 mcolor = vec3(0.0);
                            mcolor = mix(color, outgoingLight,0.);

                            gl_FragColor = vec4(mcolor,1.0);

                        `)

    };

    material.skinning = true;
    return material;

}
