import React, { useRef, useState, useEffect } from "react"
import { useGLTF } from "@react-three/drei/core/useGLTF"
import { useFrame, useThree } from "react-three-fiber"

export default function Phone({ position, scale, rotation, ...props }) {
  const group = useRef()

  const { nodes, materials } = useGLTF("models/iphoned02.glb")

  useFrame(
    ({ clock, state }) =>
      (group.current.rotation.x = group.current.rotation.y = group.current.rotation.z = Math.sin(clock.getElapsedTime() + 3) * 0.07),
  )
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={position} rotation={rotation} scale={scale}>
        <mesh material={materials.ncase} geometry={nodes.Plane_1.geometry}>
          <meshStandardMaterial metalness={1} roughness={0.05} color={"black"} />
        </mesh>
        <mesh material={materials.screen} geometry={nodes.Plane_2.geometry}></mesh>
      </group>
    </group>
  )
}

useGLTF.preload("models/iphoned02.glb")
