import React from "react"

import Torso from "../model/TorsoBoned.js"

import TorsoMobile from "../model/TorsoBonedMobile.js"
import Rig from "../elements/Rig.js"
import BoxL from "../elements/Box.js"
import BoxR from "../elements/BoxR.js"

import { RectAreaLightUniformsLib } from "three/examples/jsm/lights/RectAreaLightUniformsLib"

RectAreaLightUniformsLib.init()

export default function Home({ mouse, orientation, mobileLeftClicked, mobileRightClicked }) {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  return (
    <>
      <ambientLight intensity={5.0} />
      {isMobile ? (
        <TorsoMobile orientation={orientation} mobileLeftClicked={mobileLeftClicked} mobileRightClicked={mobileRightClicked} />
      ) : (
        <Torso mouse={mouse} />
      )}
      <BoxL mobileLeftClicked={mobileLeftClicked} mobileRightClicked={mobileRightClicked} />
      <BoxR mobileLeftClicked={mobileLeftClicked} mobileRightClicked={mobileRightClicked} />
      <Rig mobileLeftClicked={mobileLeftClicked} mobileRightClicked={mobileRightClicked} />
    </>
  )
}
