import React, { useRef, useState } from "react"
import { Box } from "@react-three/drei"
import { useFrame, useThree, useResource } from "react-three-fiber"
import { useSpring, a, config } from "react-spring/three"
import Plant from "../model/Plant.js"
import Title from "../elements/Title.js"
import { COLORS } from "../_helpers/Colors.js"
import { WaveFog } from "../_shaders/threeMod/WaveFog.js"
import { useLocation } from "wouter"

import Image from "../elements/Image.js"
import state from "../state.js"

const APlant = a(Plant)
const ABox = a(Box)

function BoxL(mobileRightClicked, mobileLeftClicked) {
  const group = useRef()
  const { viewport } = useThree()
  const [hovered, setHovered] = useState(false)

  const isIOS = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const props = useSpring({
    // clicked
    // clickWidth: clicked ? [-viewport.width / 1.05,0,-8] : [-viewport.width/2 ,0 , -6 ],

    // BOX
    position: hovered ? [-viewport.width / 3, -1, -6.75] : [-1, -7, -7.75],
    scale: hovered ? [0.16, 0.16, 0.16] : [0.07, 0.07, 0.07],
    rotation: hovered ? [2.4, 0.1, -0.2] : [2.5, 3.2, 3.5],

    positionMobile: [-viewport.width / 3.0, 0.5, -5.75],

    scaleMobile: [0.15, 0.15, 0.15],
    rotationMobile: [1.4, 0.0, 0.2],

    positionIOS: mobileRightClicked.mobileLeftClicked ? [-viewport.width / 3.0, -1.5, -5.75] : [-1, -7, -7.75],
    scaleIOS: mobileRightClicked.mobileLeftClicked ? [0.1, 0.1, 0.1] : [0.07, 0.07, 0.07],
    rotationIOS: [2.2, 0.0, 1.2],

    // Text
    color: hovered ? COLORS.white : COLORS.white,
    colorMobile: mobileRightClicked.mobileLeftClicked ? COLORS.c05 : "#E2E2E2",
    oColor: hovered ? COLORS.c05 : "#E2E2E2",
    outlineColor: hovered ? COLORS.c05 : "#E2E2E2",

    outlineColor: mobileRightClicked.mobileLeftClicked ? COLORS.c05 : "#E2E2E2",

    // Light
    intensity: hovered ? 25 : 0,
    lightPos: hovered ? [-7, 0, -2] : [1, 0, 0],
    lightPos: hovered ? [-4, 3, -2] : [1, 0, 0],

    //shader opacity
    shaderOpacity: hovered ? 0.2 : 0.2,
    shaderOpacityMobile: 0.1,
    fogC: hovered ? 45.0 : 105.0,
    fogCmobile: 125.0,
    shaderColorR: hovered ? 1.0 : 0.7,
    shaderColorG: hovered ? 0.1 : 0.1,
    shaderColorB: hovered ? 0.4 : 0.2,

    v01: hovered ? [-viewport.width / 1.77, 4, -5.5] : [-viewport.width / 5, -4, -4.5],
    r01: hovered ? [3, 2.7, 3.5] : [3, 2, 2.75],

    v02: hovered ? [-viewport.width / 1.25, -3, -4.5] : [-viewport.width / 5, -4, -3.5],

    // General Config
    config: {
      tension: 24,
      friction: 8,
    },
  })

  const [location, setLocation] = useLocation()

  function navigate() {
    setLocation("/fashion")
  }

  return (
    <group ref={group} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
      {isIOS ? (
        <ABox
          material={WaveFog(props.shaderOpacityMobile, props.fogCmobile, props.shaderColorR, props.shaderColorG, props.shaderColorB)}
          rotation={[0.0, 0.0, 0.0]}
          args={[viewport.width * 4.0, viewport.height * 5.5]}
          position={[-viewport.width * 2, 0, -8]}
        />
      ) : (
        <ABox
          material={WaveFog(props.shaderOpacity, props.fogC, props.shaderColorR, props.shaderColorG, props.shaderColorB)}
          rotation={[0.0, 0.0, -0.2]}
          args={[viewport.width * 2.0, viewport.height * 5.5]}
          position={[-viewport.width / 1.05, 0, -8]}
        />
      )}

      {isIOS ? (
        <Title
          color={props.colorMobile}
          outlineColor={props.outlineColor}
          content="Fashion"
          position={[-viewport.width / 2.8, 2.7, -1.0]}
        />
      ) : (
        <Title color={props.oColor} outlineColor={props.outlineColor} content="Fashion" position={[-viewport.width / 3.5, 0, -0.5]} />
      )}

      {isIOS ? (
        <APlant position={props.positionIOS} rotation={props.rotationIOS} scale={props.scaleIOS} />
      ) : (
        <APlant position={props.position} rotation={props.rotation} scale={props.scale} />
      )}
      <a.spotLight position={props.lightPos} color={COLORS.c02} intensity={props.intensity} />
      <a.spotLight position={props.lightPos} color={COLORS.c05} intensity={props.intensity} />
    </group>
  )
}

export default BoxL
